import React from 'react'
import PropTypes from 'prop-types'

// Components
import Layout from '../components/Layout'
import SEO from '../components/SEO';

export const BlogPostTemplate = ({
    content,
    basic_content,
    categories,
    tags,
    title,
    date,
    author,
    id
}) => {
    return (
      <div />
    )
}

BlogPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    title: PropTypes.string,
}

const BlogPost = () => {
  // const { wordpressPost: post } = data

    return (
      <Layout>
        <SEO title="post" />
        <BlogPostTemplate />
      </Layout>
    )
}

BlogPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
}

export default BlogPost
